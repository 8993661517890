@tailwind base;
@tailwind components;
@tailwind utilities;

*::-webkit-scrollbar {
  width: 8px;
  height: 12px;
  /* Width of the scrollbar */
}

*::-webkit-scrollbar-thumb {
  background-color: #cecece;
  /* Color of the thumb (the draggable part of the scrollbar) */
  border-radius: 3px;
  /* Rounded corners for the thumb */
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #6b7280;
  /* Color of the thumb on hover */
}

*::-webkit-scrollbar-track {
  background-color: #f1f5f9;
  /* Color of the track (the area behind the thumb) */
  border-radius: 3px;
  /* Rounded corners for the track */
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


